'use client'
import Script from 'next/script'
//impor blukai, removido
import { GoogleTagManager } from '@next/third-parties/google'

export function Scripts() {
  return <>
    {/*blukai script, removido*/}
    <Script id="nebula" src="https://nebula-cdn.kampyle.com/us/wu/615254/onsite/embed.js" async></Script>
    <Script id="analytics">
      {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${process.env.NEXT_PUBLIC_PIXEL}');
        fbq('track', 'PageView');
      `}
    </Script>
    <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_TAG} />
    <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_TAG2} />
  </>
}
